import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Card } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as style from './TwoImgWithHeaderAndList.module.scss';

export const fragment = graphql`
  fragment TwoImgWithHeaderAndListFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoImgWithHeaderAndList {  
      headerSection
      boxes {
        title
        text
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 620
                quality: 75
                placeholder: NONE
              )
            }
          }
        }
        options {
          optionTitle
          optionText
        }
      } 
  }
`

const TwoImgWithHeaderAndList = ({headerSection,boxes}) => {
  

  return (
      <section className="section">
        <Container>
          { headerSection && <h1 className={`h2 mb-4`} dangerouslySetInnerHTML={{ __html: headerSection }} />}
          <Row>
            {
              boxes && boxes.map((item, i) => {
                return (
                  <Col sm={12} md={6} key={i} className="mb-2" >
                    <Card>
                      <div className="position-relative">
                        {item.image && (<GatsbyImage
                            image={getImage(item.image.localFile)}
                            alt={item.image.altText ? item.image.altText : "Image " + i}
                            className={style.mainImg}
                        />)}
                        <div className={style.bgOverlay}></div>
                          <div className={`text-white text-center mx-auto ${style.imgDiv}`}>
                            <div className={`h2 px-11 text-uppercase mb-0`} dangerouslySetInnerHTML={{ __html: item.title }} />
                            <p className={style.imgText} dangerouslySetInnerHTML={{ __html: item.text }} />
                          </div>
                      </div>

                      <Card.Body>
                        {
                          item.options.map((item, i) => {
                            return (
                              <div className="pt-3">
                                <p className="text-uppercase pl-3" key={i} dangerouslySetInnerHTML={{ __html: item.optionTitle }} />
                                <div dangerouslySetInnerHTML={{ __html: item.optionText }} />
                              </div>
                            )
                          })}
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </section>
  )
}

export default TwoImgWithHeaderAndList

