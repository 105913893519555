// extracted by mini-css-extract-plugin
export var bgGrey100 = "TwoImgWithHeaderAndList-module--bg-grey-100--10ef0";
export var bgGrey150 = "TwoImgWithHeaderAndList-module--bg-grey-150--eabba";
export var bgGrey200 = "TwoImgWithHeaderAndList-module--bg-grey-200--994ef";
export var bgGrey300 = "TwoImgWithHeaderAndList-module--bg-grey-300--68dce";
export var bgGrey400 = "TwoImgWithHeaderAndList-module--bg-grey-400--0084d";
export var bgGrey500 = "TwoImgWithHeaderAndList-module--bg-grey-500--22105";
export var bgGrey600 = "TwoImgWithHeaderAndList-module--bg-grey-600--520e3";
export var bgGrey700 = "TwoImgWithHeaderAndList-module--bg-grey-700--7199f";
export var bgGrey800 = "TwoImgWithHeaderAndList-module--bg-grey-800--83e3d";
export var bgGrey900 = "TwoImgWithHeaderAndList-module--bg-grey-900--7e78f";
export var bgOverlay = "TwoImgWithHeaderAndList-module--bgOverlay--f816a";
export var imgDiv = "TwoImgWithHeaderAndList-module--imgDiv--df3ed";
export var imgText = "TwoImgWithHeaderAndList-module--imgText--fee36";
export var mainImg = "TwoImgWithHeaderAndList-module--mainImg--fba36";
export var overlay = "TwoImgWithHeaderAndList-module--overlay--71857";
export var textGrey100 = "TwoImgWithHeaderAndList-module--text-grey-100--93229";
export var textGrey150 = "TwoImgWithHeaderAndList-module--text-grey-150--62a83";
export var textGrey200 = "TwoImgWithHeaderAndList-module--text-grey-200--cbebd";
export var textGrey300 = "TwoImgWithHeaderAndList-module--text-grey-300--c0c02";
export var textGrey400 = "TwoImgWithHeaderAndList-module--text-grey-400--178ce";
export var textGrey500 = "TwoImgWithHeaderAndList-module--text-grey-500--3db4c";
export var textGrey600 = "TwoImgWithHeaderAndList-module--text-grey-600--a5ca7";
export var textGrey700 = "TwoImgWithHeaderAndList-module--text-grey-700--45fb2";
export var textGrey800 = "TwoImgWithHeaderAndList-module--text-grey-800--cf605";
export var textGrey900 = "TwoImgWithHeaderAndList-module--text-grey-900--20bf8";